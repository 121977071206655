import "./events.scss";
import "./events-blocks.scss";

import * as CarrouselContent from "../../../shared/carrouselcontent";
import * as dompack from "dompack";

/*
We might use this layer for a mobile view in which you can swipe to a next event:



window.__carrousel = [];
*/

dompack.register(".widget-events", initEventsWidget);
  // widgetnode => new CarrouselContent(widgetnode)
// );

function initEventsWidget(widgetnode)
{
  window.eventscarrousel = new CarrouselContent.CarrouselContent(widgetnode
            , { viewport:            ".widget-events__blockswrapper"
              , viewport_inner:      ".widget-events__blocks"
              , itemsselector:       ".widget-event__block__top" // measure the child because .widget-event__block may be display: contents;
              , bulletscontainer:    ".widget-events__bullets"
              , bulletclass:         "carrouselbullet"
              , bulletselectedclass: "carrouselbullet--selected"

              //, previousbutton:      widgetnode.querySelector(".fullscreenslideshow__previousbutton")
              //, nextbutton:          widgetnode.querySelector(".fullscreenslideshow__nextbutton")

              , debug: false
              , __test: "eventswidget"

              });
  // widgetnode.__slideshowinstance = slideshow;
  // window.__carrousel.push(eventscarrousel);
}
