import "./photoalbum.scss";
import * as dompack from "dompack";
import * as CarrouselContent from "../../../shared/carrouselcontent";
import "./fullscreen-slideshow.scss";


window.addEventListener("click", evt => doCheckForImageSelection(evt));


function doCheckForImageSelection(evt)
{
  let slideidx = 0;


  // Check for click on close button
  if (dompack.closest(evt.target, ".fullscreenslideshow__close"))
  {
    document.documentElement.classList.remove("fullscreenslideshowactive");
    let slideshow = dompack.closest(evt.target, ".fullscreenslideshow");
    slideshow.classList.remove("fullscreenslideshow--active");
    return;
  }




  let activationnode = dompack.closest(evt.target, ".widget-photoalbum__viewalbum");
  if (!activationnode)
  {
    activationnode = dompack.closest(evt.target, ".widget-photoalbum__item");
    //let slide = dompack.closest(activationnode, "[data-slideidx]");

    if (activationnode)
      slideidx = parseInt(activationnode.getAttribute("data-slideidx"));
  }

  if (!activationnode)
    return;


  let widgetnode = dompack.closest(evt.target, ".widget-photoalbum");
  let slidescollectionguid = widgetnode.dataset.instanceid;
  console.log("Activating slideshow", slidescollectionguid, slideidx);


  let slideshow = document.querySelector('.fullscreenslideshow[data-instanceid="'+slidescollectionguid+'"]');
  console.log("Slideshow node", slideshow);


  document.documentElement.classList.add("fullscreenslideshowactive");

  slideshow.classList.add("fullscreenslideshow--active");
  slideshow.__slideshowinstance.refresh();
  slideshow.__slideshowinstance.gotoPage(slideidx);

  console.log("Slideshow node", slideshow);
}



dompack.register(".fullscreenslideshow", initSlideshow);

function initSlideshow(widgetnode)
{
  let slideshow = new CarrouselContent.CarrouselContent(widgetnode
            , { viewport:            ".fullscreenslideshow__items"
              , viewport_inner:      ".fullscreenslideshow__items"
              , itemsselector:       ".fullscreenslideshow__item"
              , bulletscontainer:    ".fullscreenslideshow__bullets"
              , bulletclass:         "fullscreenslideshow__bullet"
              , bulletselectedclass: "fullscreenslideshow__bullet--selected"
              , __test: "blocks"

              , previousbutton:      widgetnode.querySelector(".fullscreenslideshow__previousbutton")
              , nextbutton:          widgetnode.querySelector(".fullscreenslideshow__nextbutton")
              });
  widgetnode.__slideshowinstance = slideshow;
}
