import * as dompack from "dompack";

import "./sidebar-container.scss";
import "./sidebar-content.scss";
import "./sidebar-menu.scss";
import "./sidebar-modalitylayer.scss";
import "./sidebar-search.scss";


/*

SPC-Sidebar - 24-may-2023

NOTE: This version assumes an item with subitems cannot be a link itself...

24-may-2023:
- Added aria-expanded and aria-control attributes
- Make code better readable

use of a click + class
- no accessibility checker complaining about double labels
- sidebar can have display: none; so we don't tab through hidden content
- we can control what needs to get focus first in the sidebar

27-dec-2023
- fixed aria-expanded to be set back to "false" when the dialog is being closed

*/


let old_focus_element = null;


window.addEventListener("keyup", evt => doCheckForEsq(evt));

dompack.onDomReady(initSlideMenu);


function initSlideMenu()
{
  if (document.documentElement.classList.contains("wh-widgetpreview"))
    return;


  let menus = document.querySelectorAll(".sidebar__menu");
  for (let menucontainer of menus)
  {
    menucontainer.addEventListener("click", evt => doToggleSideBarMenuItems(evt));
    menucontainer.addEventListener("keyup", evt => doToggleSideBarMenuItems(evt));
  }

  addActionButtonListeners();

  // detect dialog closing (so we can update the aria-expanded of the toggle button)
  document.getElementById("slidemenu-container").addEventListener("close", onClosingSidebar);

  enrichWithAriaAttributes();

  setSidebarState(false, false);
}


/** @short enable sidebar toggle and close buttons to work (which can be anywhere on the page)
 */
function addActionButtonListeners()
{
  let togglebuttons = document.querySelectorAll(".sidebar-action-toggle");
  for (let node of togglebuttons)
  {
    if (node.tagName != "BUTTON")
      console.error(".sidebar-action-toggle should be a button but it's an %s", node.tagName);

    node.addEventListener("click", doToggleSidebar);
  }


  let closebuttons = document.querySelectorAll(".sidebar-action-close");
  for (let node of closebuttons)
  {
    if (node.tagName != "BUTTON")
      console.error(".sidebar-close should be a button but it's an %s", node.tagName);

    node.addEventListener("click", evt => setSidebarState(false));
  }
}


/** @short Add aria attributes to communicate to the screereader that there are subitems which can be expanded
 */
function enrichWithAriaAttributes()
{
  // Add all aria-controls and aria-expanded attributes
  let items_withsubitems = document.querySelectorAll(".sidemainmenu__item--hassubitems");
  let branchidx = 0;

  for (let branchnode of items_withsubitems)
  {
    branchidx++;
    setBranchState(branchnode, branchnode.classList.contains("sidemainmenu__item--expand"), branchidx);
  }
}




function doCheckForEsq(evt)
{
  if (evt.key == "Escape" && isSidebarActive())
    setSidebarState(false, true);
}




function isSidebarActive()
{
  let sidebar = document.getElementById("slidemenu-container");
  return sidebar.hasAttribute("open");
}

function doToggleSidebar()
{
  console.info("TOGGLE");
  setSidebarState(!isSidebarActive(), true);
}

function onClosingSidebar()
{
  setSidebarState(false, false); // FOXME: should we restore focus?
}



function setSidebarState(show, override_focus)
{
  //console.log("setSidebarState", show, override_focus);

  let sidebaractive = isSidebarActive();

  old_focus_element = document.activeElement;

  // We only want to mess with focus if a sidebar-action-toggle / sidebar-action-close button was used
  // When focus is set directly to within the sidebar menu (maybe a screenreader moving to a <nav> landmark)
  // we don't want to mess with the focus.
  if (override_focus)
  {
      if (show && !sidebaractive) // are we making the sidebar appear?
      {
        //let first_focusable = document.getElementById("slidemenu").querySelector('button, a[href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
        let first_menu_item = document.querySelector(".sidebar__menu").querySelector("a");
        if (first_menu_item)
          first_menu_item.focus();
      }
      else if (!show && sidebaractive) // are we hiding the sidebar?
      {
        console.info("Restoring focus to", old_focus_element);
        if (old_focus_element)
        {
          old_focus_element.focus();
          old_focus_element = null;
        }
      }
  }

  document.documentElement.classList.toggle("sidebar-enabled", show);


  let sidebar = document.getElementById("slidemenu-container");

  if (show)
  {
    if (sidebar.showModal)
      sidebar.showModal();
    else
      sidebar.setAttribute("open", "");
  }
  else
  {
    if (sidebar.showModal)
      sidebar.close();
    else
      sidebar.removeAttribute("open");
  }

  let togglebuttons = document.querySelectorAll(".sidebar-action-toggle");
  for (let node of togglebuttons)
  {
    console.log("Update aria-expanded on", node);
    node.setAttribute("aria-expanded", show ? "true" : "false");
  }


  let anchors = document.getElementById("slidemenu").querySelectorAll("button, a[href], input, select, textarea");
  for (let node of anchors)
  {
    if (show)
      node.removeAttribute("tabindex");
    else
      node.setAttribute("tabindex", "-1"); // remove from tab navigation, but keep available to screenreader, even while the menu is visually hidden
  }
}


function setBranchState(node, expanded, branchidx)
{
  let activatedtogglenode = node.querySelector(".sidemainmenu__item__toggle");

  let activatedlink = node.classList.contains("sidemainmenu__item__link")
                              ? node
                              : node.querySelector(".sidemainmenu__item__link");

// console.info("setBranchState", node, expanded);
// console.log("togglenode", activatedtogglenode);
// console.log("activatedlink", activatedlink);

  node.classList.toggle("sidemainmenu__item--expand", expanded);
/*
  if (activatedtogglenode)
    activatedtogglenode.setAttribute("aria-expanded", expanded ? "true" : "false");
*/
  activatedlink.setAttribute("aria-expanded", expanded ? "true" : "false");

  if (branchidx)
  {
    let panelid = "spc-sidebar-branch"+branchidx;

    node.querySelector("ul").setAttribute("id", panelid);
/*
    if (activatedtogglenode)
      activatedtogglenode.setAttribute("aria-controls", panelid);
*/
    activatedlink.setAttribute("aria-controls", panelid);
  }
}

function toggleBranchState(branchnode)
{
  let expanded = branchnode.classList.contains("sidemainmenu__item--expand");
  //console.warn("!!toggleBranchState - state was", expanded, "now state will be", !expanded, "for node", branchnode);
  setBranchState(branchnode, !expanded);
}


function doToggleSideBarMenuItems(evt)
{
  let branchnode = evt.target.closest(".sidemainmenu__item");
  if (!branchnode || !branchnode.classList.contains("sidemainmenu__item--hassubitems"))
  {
    console.log("Not within a sidemainmenu item or no subitems to expand.", evt);
    return;
  }

  let activatedtogglenode = evt.target.closest(".sidemainmenu__item__toggle");

  let activatedlink = evt.target.classList.contains("sidemainmenu__item__link")
                              ? evt.target
                              : evt.target.closest(".sidemainmenu__item__link");

  // console.log("togglenode", activatedtogglenode);
  // console.log("activatedlink", activatedlink);


  if (evt.type == "click" && (activatedtogglenode || (activatedlink && !activatedlink.hasAttribute("href"))))
  {
    evt.preventDefault();
    toggleBranchState(branchnode);
  }

  if (evt.type == "keyup")
  {
    switch(evt.key)
    {
      case "ArrowRight":
      {
        evt.preventDefault();
        setBranchState(branchnode, true); // expand to show subitems
        break;
      }
      case "ArrowLeft":
      {
        evt.preventDefault();
        setBranchState(branchnode, false); // collapse to hide subitems
        break;
      }
      case " ":
      {
        evt.preventDefault();
        toggleBranchState(branchnode);
        break;
      }
    }
  }

  // ignore, let the browser pick up this link
}
