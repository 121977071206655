//We are the only videojs user right now, so keep it all here:

import * as dompack from 'dompack';
import videojs from "video.js";
import "video.js/dist/video-js.css";
import toWebVTT from "./srt-webvtt"; // This is a default export, so you don't have to worry about the import name
import ToggleCaption from './togglecaption';

import './rovid.scss';
import './rovid-controls.scss';

import "./rovid-jzojp.scss";


class VideoPlayer
{
  constructor(node)
  {
    this.node = node;
    this.restorePlaybackTime = 0;
    this.enabledAudioTrackAD = false;

    const elVideo = node.querySelector("video");

    this.videoplayer = new videojs(elVideo,
        { controls: true
        , preload: 'auto'
        , aspectRatio: '16:9'
        });

    // remove the normal subtitles button
    const controlBar = this.videoplayer.getChild('ControlBar');
    controlBar.removeChild(controlBar.getChild('SubsCapsButton'));

    // setup subtitles
    this.videoplayer.on('ready', async() =>
    {
      const srtURL = elVideo.dataset ? elVideo.dataset.srt : '';
      if (srtURL)
      {
        console.info("Adding subtitles track", srtURL);
        this.addRemoteTextTrackBySRT(srtURL,
            { button:        node.querySelector('.vjs-captions-toggle.cc')
            , type:          'subtitles'
            , className:     'cc'
            , iconText:      'CC'
            , title:         'Ondertiteling' // This is the text screenreaders will use
            , manualCleanup: false
            });
      }

      /* audio descriptions closed captions
         "Audio description narrates the crucial visual elements that would be necessary for comprehension without the ability to see the screen. It describes non-verbal cues like gestures, facial expressions, or eye contact, as well as objects or scenery that’s important to understanding the plot. In an educational lecture, audio description can be used to describe charts, graphs, and diagrams."

      const adURL = elVideo.dataset ? elVideo.dataset.adsrt : '';
      if (adURL)
      {
        console.info("Adding AD closed captions track", srtURL);
        this.addRemoteTextTrackBySRT(adURL,
            { button:        node.querySelector('.vjs-captions-toggle.ad')
            , type:          'captions'
            , className:     'ad'
            , iconText:      'AD'
            , title:         'Audiodescriptie'
            , manualCleanup: false
            });
      }
      */

      const adVideoURL = elVideo.dataset ? elVideo.dataset.videowidthad : '';
      if (adVideoURL)
      {
        console.info("Adding AD closed captions track", srtURL);
        this.addRemoteTextTrackBySRT(adVideoURL,
            { button:        node.querySelector('.vjs-captions-toggle.ad')
            , type:          'captions'
            , className:     'ad'
            , iconText:      '!!'
            , title:         'Audiodescriptie'
            , manualCleanup: false
            });
      }



      const adVideoButton = this.videoplayer.controlBar.addChild("button", { text: "Blubvis!" });
      adVideoButton.addClass("wh-rovid-switchad");
      adVideoButton.on("click", evt => this.switchBetweenNormalAndAD(evt));

      elVideo.addEventListener("loadedmetadata", evt => this.doRestoreTimeAfterVideoSwitch(evt));

      // const audiotracks = this.videoplayer.audioTracks();
      // console.info(audiotracks);
// this.fixOrder();
    });
  }

  doRestoreTimeAfterVideoSwitch(evt)
  {
    if (this.restorePlaybackTime > 0)
    {
      const elVideo = this.node.querySelector("video");
      elVideo.currentTime = this.restorePlaybackTime;
      this.restorePlaybackTime = 0;
    }

    // setTimeout(this.fixOrder.bind(this), 100);
  }

  fixOrder()
  {
    console.log("FIXORDER");

    // Reorder all videoplayer buttons
    const order = [".vjs-play-control", ".vjs-time-control", ".vjs-volume-control", ".vjs-captions-toggle", ".wh-rovid-switchad", ".vjs-fullscreen-control"];
    const controlbar = this.node.querySelector(".vjs-control-bar");

    // Reappend all controlbar items in our specified order
    for (const selector of order)
    {
      const node = controlbar.querySelector(order);
      console.log("REORDER", selector, node);
      controlbar.appendChild(node);
    }
  }

  switchBetweenNormalAndAD(evt)
  {
    console.log("SWITCH", evt);

    const elVideo = this.node.querySelector("video");

    this.enabledAudioTrackAD = !this.enabledAudioTrackAD;

    const videosource = this.enabledAudioTrackAD ? elVideo.dataset.videowidthad : elVideo.querySelector("source").getAttribute("src");

    // console.log("video", elVideo);
    // console.log("currentTime", elVideo.currentTime);
    this.restorePlaybackTime = elVideo.currentTime - 1;

    console.log("Switch to", videosource, "with time", this.restorePlaybackTime);

    this.videoplayer.src(videosource);
    this.videoplayer.play();
  }

  // adds a track and a button to enable it
  async addRemoteTextTrackBySRT(srtURL = '', options = {})
  {
    let webVTTURL;
    if (srtURL.endsWith(".srt"))
      webVTTURL = await this.convertSrtURLToWebVTT(srtURL);
    else if (srtURL.endsWith(".vtt"))
      webVTTURL = srtURL;

    if (webVTTURL)
    {
      this.videoplayer.addRemoteTextTrack(
          { kind: options.type
          , src: webVTTURL
          , mode: 'hidden'
          , manualCleanup: true
          });

      const controlBar = this.videoplayer.getChild('ControlBar');
      controlBar.addChild(new ToggleCaption(this.videoplayer, {}, options, this.node));
    }
  }

  // returns a DOMString containing a URL to a WebVTT file (converted from SRT)
  async convertSrtURLToWebVTT(url = '')
  {
    /*
    const srtBlob = await fetch(url).then(r => r.text());
    console.log("Received", srtBlob);
    const convertedWebVTT = srt2webvtt(srtBlob);
    console.log("Converted to WebVTT", convertedWebVTT);

    const vttBlob = new Blob([convertedWebVTT], {type : 'text/vtt'});
    return URL.createObjectURL(vttBlob);
    */
    const srtBlob = await fetch(url).then(r => r.blob());
    const vttBlobURL = toWebVTT(srtBlob);
    return vttBlobURL;

  }
}

dompack.register(".widget--rovid", (node) => new VideoPlayer(node));
