// Copied from WVHG csuggest.es, ask Herman for implementation details

import * as dompack from "dompack";
//import JSONRPC from "@mod-system/js/net/jsonrpc";
import consiliosearch from "@mod-consilio/js/internal/search.rpc.json";
import * as encoding from "dompack/types/text";
//import getTid from "@mod-tollium/js/gettid";
import * as whintegration from "@mod-system/js/wh/integration";

/*

FIXME: changed made to positioning... added an extra wrapper which is for positioning, so it's anchored close to the input.



  cSearchSuggest
    suggest container is inserted in end of search form
  options: catalog: string , consilio catalog name
           rpc: boolean    , switch when option is selected then plain submit or just fire submit event for rpc
*/
export default class cSearchSuggest
{
  constructor( inputnode )
  {
    this.inputnode = inputnode;

    let suggestdata = inputnode.getAttribute("data-suggest");

    try
    {
      this.options = JSON.parse(suggestdata);
    }
    catch(err)
    {
      this.options = { autoposition: true
                     , catalog: suggestdata
                     };
    }

    if( !this.options.catalog )
    {
      console.warn("No catalog set");
      return;
    }



    this.formnode = dompack.closest(this.inputnode, "form");
    this.attachnode = inputnode.dataset.suggestparent === "parent" ? inputnode.parentNode : this.formnode;

    /*
    console.info({ suggestparent: inputnode.dataset.suggestparent
                 , attachnode: this.attachnode
                 });
    */

    let suggestcontainer = inputnode.closest("[data-suggest-container]");
    if (suggestcontainer)
      this.attachnode = suggestcontainer;

//    this.autoposition = inputnode.dataset.autoposition;
    this.autoposition = this.options.autoposition;
    if( this.autoposition )
    {
      window.addEventListener("resize", ev => {
        if( !this.suggestwrapper )
          return;
        this.positionSuggestWrapper();
      });
    }

    this.history = [];

    document.body.addEventListener("click", ev =>
    {
      if( !this.suggestwrapper )
        return;

      let chknode = dompack.closest(ev.target, "form");
      if( !chknode || chknode !== this.formnode )
        this.removeSuggestions();
    });

    this.words = this.inputnode.value;
    this.inputnode.addEventListener("keyup", ev =>
    {
      if( this.suggestwrapper && ev.keyCode === 40 )
      { //down 40, up 38
        ev.preventDefault();
        this.suggestwrapper.querySelector("li").focus();
      }

      if( this.updatetimer )
        clearTimeout(this.updatetimer);

      let inpval = this.inputnode.value;
      if( inpval.trim )
        inpval = inpval.trim();

      if( inpval !== this.words )
        this.updatetimer = setTimeout( ev => this.updateList( inpval ), 200);
    });

    this.inputnode.addEventListener("search", ev => this.removeSuggestions() );//case search clear field
  }

  async updateList( words )
  {
    this.words = words;

    let minwordlength = 3;

    //first check if we have already suggestions for given input
    for( let i = this.history.length - 1; i >= 0 && this.words.length >= minwordlength; --i)
    {
      if( this.history[i].words === this.words )
      {
        this.updateSuggestions(this.history[i].values);
        return;
      }
    }

    if( this.words !== "" && this.words.length >= minwordlength )
    {
      if(this.suggestionrpc)
        consiliosearch.rpcResolve(this.suggestionrpc, null);

      this.suggestionrpc = consiliosearch.suggest(
        { type: "catalog"
        , catalog: this.options.catalog
        }
        , this.words
        , { doccount: ""
          , count: 10
          });

      let results = await this.suggestionrpc;
      if(results)
        this.updateSuggestions(results.values);
    }
    else if( this.suggestwrapper )
      this.removeSuggestions();
  }

  updateSuggestions( suggestions )
  {
    this.formnode.classList.add("suggestionsactive");

    this.history.push({"words" : this.words, "values" : suggestions });
    if( this.history.length > 100 ) //limit nr items in history
      this.history.shift();

    if( !this.suggestwrapper )
    {
      this.listitems = [];

      this.suggestouterwrapper = dompack.create("div",{ "className" : "wh-autocomplete-container"} );

      this.suggestwrapper = dompack.create("ul",{ "className" : "wh-autocomplete-values"} );
      this.suggestouterwrapper.appendChild(this.suggestwrapper);


      // Find the node containing our input
      let referenceElement = this.inputnode;

      if (referenceElement.parent !== this.attachnode)
      {
        let node = this.attachnode.firstChild;
        while(node && !node.contains(this.inputnode))
          node = node.nextElementSibling;

        referenceElement = node;
      }

      /*
      console.log("Attach node", this.attachnode);
      console.log("Element containing our input", referenceElement);
      */

      // this.attachnode.appendChild(this.suggestouterwrapper);

      // By inserting directly after the element containing our searchbar
      // we can position the dropdown absolutely but still use the position in our flex
      // container directly below that searchbar.
      this.attachnode.insertBefore(this.suggestouterwrapper, referenceElement.nextElementSibling);

      if( this.autoposition )
        this.positionSuggestWrapper();

      this.suggestwrapper.addEventListener("keydown", ev =>
      {
        if( ev.keyCode === 38 )
        { // Up
          ev.preventDefault();

          let focusednode = this.inputnode;
          for(let i = this.listitems.length - 1; i >= 0; --i)
          {
            if( document.activeElement === this.listitems[i] )
            {
              if( i > 0 )
                focusednode = this.listitems[i - 1];
              break;
            }
          }
          focusednode.focus();
        }
        else if( ev.keyCode === 40 )
        {// Down
          ev.preventDefault();

          let focusednode = this.inputnode;
          for(let i = 0; i < this.listitems.length; ++i)
          {
            if( document.activeElement === this.listitems[i] )
            {
              if(i < this.listitems.length - 1)
                focusednode = this.listitems[i + 1];
              break;
            }
          }
          focusednode.focus();
        }
        else if( ev.keyCode === 27 ) // Esc
        {
          this.inputnode.focus();
          this.removeSuggestions();
        }
        else if( ev.keyCode === 13 ) // Enter
        {
          let item = dompack.closest( ev.target, "li");
          if( item )
          {
            this.inputnode.value = item.getAttribute("data-value");

// Return the focus to the input, because upon clickiong the focus goes to the suggestions container and when it is removed from the DOM the focus goed back to the <body>
this.inputnode.focus();

            this.removeSuggestions();//remove list

// FIXME: DON'T use submit(), it circumvents any eventlisteners added to submit of the form
//            if( this.options.rpc ) // trigger Rpc
              dompack.dispatchCustomEvent(this.formnode, "submit", { bubbles: false, cancelable: true});
//            else
//              this.formnode.submit();//basic submit
          }
        }
      });
    }

    this.suggestwrapper.innerHTML = "";//first empty container
/*
    if( !suggestions.length )
    {
      let node = dompack.create("li", { className : "noresults", "innerHTML" : getTid('helicon_base:webdesigns.helicon2018.frontend.js.noresults') } );
      this.suggestwrapper.appendChild(node);
      return;
    }
*/
    for( let item of suggestions )
    {
      let val = encoding.encodeTextNode(item.value);
      let line = val.replace(this.words, "<span class=\"match\">" + encoding.encodeTextNode(this.words) + "</span>");

      let node = dompack.create("li", { className : "suggestion", "innerHTML" : line } );
      node.setAttribute("tabindex", "0");
      node.setAttribute("data-value", item.value);

      node.addEventListener("click", ev => {
        this.inputnode.value = item.value;

this.inputnode.focus();
        this.removeSuggestions();//hide/remove list

// FIXME: DON'T use submit(), it circumvents any eventlisteners added to submit of the form
//        if( this.options.rpc ) // trigger Rpc
//          dompack.dispatchCustomEvent(this.formnode, "submit", { bubbles: false, cancelable: true});
//        else

// FIXME: why doesn't dispatchCustomEvent work in the JZOJP search field()
          this.formnode.submit();//basic submit
      });

      this.listitems.push(node);

      this.suggestwrapper.appendChild(node);
    }
  }

  positionSuggestWrapper()
  {
/*
    let pos1 = this.attachnode.getBoundingClientRect(); // positioning container
    let pos2 = this.inputnode.getBoundingClientRect();

    //First set position wrapper to zero
    this.suggestwrapper.style.left = "0px";
    this.suggestwrapper.style.right = "0px";
    this.suggestwrapper.style.top = "0px";
    this.suggestwrapper.clientWidth;//force css refresh

    let x1 = pos2.left - pos1.left;
    if( x1 != 0 )
      this.suggestwrapper.style.left = x1 + "px";

    let x2 = pos1.right - pos2.right;
    if( x2 != 0 )
      this.suggestwrapper.style.right = x2 + "px";

    let y1 = pos2.bottom - pos1.top;
    if( y1 != 0 )
      this.suggestwrapper.style.top = y1 + "px";
*/
  }

  removeSuggestions()
  {
    this.formnode.classList.remove("suggestionsactive");

    if( !this.suggestwrapper )
      return;
    this.suggestouterwrapper.parentNode.removeChild( this.suggestouterwrapper );

    this.suggestwrapper = null;
    this.suggestouterwrapper = null;
  }
}
