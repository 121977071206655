// Tag to find which modules use this code: SPC/forms-feedback-hacks

import * as dompack from "dompack";


let formsObserver = new MutationObserver(checkForAddedFormError);

dompack.onDomReady(addFormsInfo);



function checkForAddedFormError(mutations)
{
  console.log(mutations);

  // Find all error messages which have been added
  for (let mutation of mutations)
  {
    if( mutation.type === "childList" && mutation.target.classList.contains("wh-form__error") )
      maybeRewriteErrorMessage(mutation.target);// update of existing error

    for (let node of mutation.addedNodes)
    {
      // console.log("added node:", node);
      if (node.nodeType === Node.ELEMENT_NODE && node.classList.contains("wh-form__error"))
        maybeRewriteErrorMessage(node);
    }
  }
}

function maybeRewriteErrorMessage(node)
{
  // Determine field name
  let fieldlabel = "";
  let fieldgroup = node.closest(".wh-form__fieldgroup");

  console.log("maybeRewriteErrorMessage", node, "in fieldgroup", fieldgroup);

  let label = fieldgroup.querySelector(".wh-form__label"); // FIXME: is this the best way??
  if (label)
  {
    fieldlabel = label.textContent;
    console.info("Label", fieldlabel);
  }
  else
    console.warn("No label");

  console.log("Error is", node.textContent, "for field", fieldlabel);

  let text = node.textContent;
  if (text === "Dit veld is verplicht.")
  {
    // node.textContent = `Het veld "${fieldlabel}" is niet gevuld maar is verplicht.`;
    node.textContent = `"${fieldlabel}" is niet ingevuld.`;
  }
  else if (text === "Dit is geen geldig e-mailadres.")
  {
    // node.textContent = `Het veld "${fieldlabel}" moet een geldig e-mailadres bevatten in de vorm van ontvanger@voorbeeld.nl.`;
    node.textContent = `"${fieldlabel}" moet een geldig e-mailadres bevatten in de vorm van ontvanger@voorbeeld.nl.`;
  }
}



function addFormsInfo()
{
  let forms = document.querySelectorAll(".wh-form");
  for (let form of forms)
    fixForm(form);
}


function fixForm(form)
{
  addInfopanel(form);

  formsObserver.observe(form, { attributes: false, childList: true, characterData: false, subtree: true });
}

function addInfopanel(form)
{
  let requiredfields = document.querySelectorAll(".wh-form__fieldgroup--required");
  console.info("Form", form, requiredfields.length);

  if (requiredfields.length == 0)
    return;
/*
  let infopanel =
      <div class="embeddedobject widget--highlight rtdcontent">
        <h3 class="widget--highlight__title heading3">Let op:</h3>
        Velden aangegeven met een * zijn verplicht om in te vullen.
      </div>;
*/

  let infopanel =
      <div class="wh-form__fieldgroup wh-form__fieldgroup--richtext wh-form__fieldgroup--custom-info">
        <div class="wh-form__fields">
          <div class="wh-form-addition__explainrequired">Velden met een sterretje(*) zijn verplicht om in te vullen.</div>
        </div>
      </div>;

  // Add to every page (FIXME)
  let pages = form.querySelectorAll(".wh-form__page");
  for(let page of pages)
  {
    let requiredfieldsinpage = page.querySelectorAll(".wh-form__fieldgroup--required");

    console.info("Page", requiredfieldsinpage, requiredfields.length);

    if (requiredfieldsinpage.length == 0)
      return;

    let first_non_text_field = page.querySelector(":scope > :not(.wh-form__fieldgroup--richtext)");
    console.log("First non-text field:", first_non_text_field);

    page.insertBefore(infopanel, first_non_text_field);
  }
}
