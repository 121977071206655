import "./video.scss";
import "./video-consentoverlay.scss";

import * as dompack from "dompack";
import * as consenthandler from '@mod-publisher/js/analytics/consenthandler';

import * as whintegration from "@mod-system/js/wh/integration";


//dompack.register(".wh-video", createConsentLayers);
dompack.register(".wh-video", addPlayButtonToVideo);

window.addEventListener("click", evt => doCheckForConsentClick(evt));

function doCheckForConsentClick(evt)
{
  if (!dompack.closest(evt.target, ".widget-video__requireconsent__accept"))
    return;

  console.log("setting consent");

  consenthandler.setConsent(["all"]);

  // let info = consenthandler.getConsentDetails();
  console.info(document.documentElement.dataset.whConsent);
}



function setConsentLayers()
{
  let videowidgets = document.querySelectorAll(".wh-video");
  //console.log("Adding consentlayers to", videowidgets);

  for( let node of videowidgets) //document.querySelectorAll("*[data-needsconsent]") )
  {
    let heading = <div class="widget-video__requireconsent__coloroverlay wh-requireconsent__overlay"></div>;

    let coloroverlay =
        <div class="widget-video__requireconsent__text wh-requireconsent__overlay">
          <div class="widget-video__requireconsent__text__heading">Dit is een YouTube video</div>
          De YouTube video kan alleen worden afgespeeld worden als je <span class="widget-video__requireconsent__accept">cookies van derden accepteert</span>.
        </div>;

     //console.log("Video", node);

     node.appendChild(heading);
     node.appendChild(coloroverlay);
  }
}

function removeConsentLayers()
{
  console.log("removeConsentLayers");

  for( let node of document.querySelectorAll("*[data-needsconsent]") )
  {
    //remove overlay with warning text
    let shieldnode = node.querySelector(".consentblocker");
    if( shieldnode )
      shieldnode.parentNode.removeChild(shieldnode);

    //If FB likebox widget, add FB iframe
    if( node.dataset.needsconsent == "facebook" )
    {
      let fbnode = node.querySelector("iframe");
      if( !fbnode )
      { //Add like box
        let fburl = encodeURIComponent(node.dataset.fburl);
        if( fburl )
        {
          fbnode = <iframe src={"//www.facebook.com/plugins/likebox.php?href=" + fburl + "&amp;width=300&amp;height=258&amp;colorscheme=light&amp;show_faces=true&amp;header=false&amp;stream=false&amp;show_border=false&amp;appId=495328497144231"} scrolling="no" frameborder="0" allowTransparency="true"></iframe>;
          node.appendChild(fbnode);
        }
      }
    }
  }
}

function addPlayButtonToVideo(node)
{
  let playbutton = node.querySelector(".wh-video__playbutton");

  // Add the SVG image to the play button
  //let image = <svg><use href={whintegration.config.imgroot + "buttons.svg#play"} width="100%" height="100%"></use></svg>

  /// We cannot use react to build the SVG use construction, because SVG has it's own namespace.
  // (when parsing the HTML the browser corrects this, but when dynamically added we must mind the namespace ourselves)
  let svgElem = document.createElementNS("http://www.w3.org/2000/svg", "svg")
  let useElem = document.createElementNS('http://www.w3.org/2000/svg', 'use');
  svgElem.setAttribute("role", "img"); // The SVG is used as a simple image/icon
  svgElem.setAttribute("aria-hidden", "true"); // This button image is decorative

  // Import the playbutton into the <svg>
  useElem.setAttribute("width", "100%"); // the SVG within must use 100% of the container's size
  useElem.setAttribute("height", "100%");
  useElem.setAttributeNS('http://www.w3.org/1999/xlink', 'href', whintegration.config.imgroot + "buttons-video.svg#video-play");

  svgElem.appendChild(useElem);
  playbutton.appendChild(svgElem);
}


//dompack.onDomReady(() =>
window.videoinit = function()
{
  //console.info("Video init", window.consenthandler);

  if( !window.consenthandler.hasConsent("video") )
    setConsentLayers();
  else
    removeConsentLayers();

  document.addEventListener("wh:consent-change", ev => {
    if( !window.consenthandler.hasConsent("video") )
      setConsentLayers();
    else
      removeConsentLayers();
  });
};
