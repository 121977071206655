import "./toggleextendedcontent.css";


window.addEventListener("click", checkForExpandToggle);

function checkForExpandToggle(evt)
{
  let toggle = evt.target.closest(".whcomponent-showmoretoggle");
  if (!toggle)
    return;

  let panelid = toggle.getAttribute("aria-controls")
  let panel = document.getElementById(panelid);

  //console.log(panelid, panel);

  if (!panel)
  {
    console.log("Cannot find the panel as specified in aria-controls.");
    return;
  }

  let expand = !!panel.hasAttribute("aria-hidden");


  // Find all toggler for this panel
  // (usually this would be a "Show more" and "Show less") element.
  let selector = `.whcomponent-showmoretoggle[aria-controls="${CSS.escape(panelid)}"]`;
  //console.info(selector);
  let toggles = document.querySelectorAll(selector);
  console.log("Toggles for this panel", toggles);
  for (let togglenode of toggles)
  {
    togglenode.setAttribute("aria-expanded", expand ? "true" : false);

    // FIXME: make generic
    if (togglenode.classList.contains("pvoverview-filters__morebutton"))
    {
      if (expand)
        togglenode.setAttribute("tabindex", "-1"); // don't kill the current focus, but don't allow focussing again using keyboard
      else
        togglenode.removeAttribute("tabindex");
    }
  }


  toggle.setAttribute("aria-expanded", expand ? "true" : false);


  panel.toggleAttribute("aria-hidden", !expand);


  //let panel = document.getElementById(toggle.getAttribute("aria-controls"));
  //panel.focus();
}
