import * as dompack from "dompack";

/*

CarrouselContent (Mark)

FIXME: Chrome 81 and newer resnap while resizing the window... However Firefox doesn't do this yet.
       Let us force snapping to the current page upon resize?

NOTE: For making an element work as <button> in an accessible way see: https://www.deque.com/blog/accessible-aria-buttons/

Use the smoothscroll-polyfill (for Safari <14 and IE) with this:
- npm i smoothscroll-polyfill
- import smoothscroll from 'smoothscroll-polyfill'; // For Safari, IE and Edge (works natively in Chrome, Firefox and iOS)
- smoothscroll.polyfill();

*/
window.__widget_blocks = [];


// Get the first carrousel which is in debug mode
window.getCC = function()
{
  for(let block of window.__widget_blocks)
  {
    if (block.widget.options.debug)
      return block.widget;
  }
}



class CarrouselContent
{
  constructor(widgetnode, options)
  {
    options =
            { viewport:            "" // __itemswrapper - Viewport
            , viewport_inner:      "" // __items - The container with the items, usually aligned to the content
            , itemsselector:       "" // __item - Selector to find items to determine how to distribute them into pages
            , bulletscontainer:    "" // __bullets - To find the container to create bullets into
            , bulletclass:         "" // __bullet - The class for each bullet / jumpbutton
            , bulletselectedclass: "" // __bullet-selected - The class to add to a bullet which indicated a selected page
            , previousbutton:      null
            , nextbutton:          null
            , debug:               false
            , debug_determineactivepage: false
            , ...options
            };
    this.options = options;
    this.activepage = 0;

    if (this.options.debug)
      console.log("[CarrouselContent] Options", options);

    window.__widget_blocks.push(
                         { node:      widgetnode
                         , widget:    this
                         });

    //console.log("CarrouselContent options", options);

    this.widgetnode = widgetnode;

    this.viewport = widgetnode.querySelector(options.viewport);
    this.viewport_inner = widgetnode.querySelector(options.viewport_inner);
    this.items = widgetnode.querySelectorAll(options.itemsselector);

    if (!this.viewport)
    {
      console.error("Missing CarrouselContent viewport for", widgetnode);
      return;
    }

    //console.log(this.options.__test + " - " + this.options.viewport_inner)

    if (!this.viewport_inner)
    {
      console.error("Missing CarrouselContent viewport_inner for", widgetnode);
      return;
    }


    if (options.bulletscontainer != "")
    {
      this.bulletscontainer = widgetnode.querySelector(options.bulletscontainer);
      if (!this.bulletscontainer)
      {
        console.error("Missing CarrouselContent bulletscontainer for", widgetnode);
        return;
      }
    }

    this.bullets = [];

    this.viewport.addEventListener("scroll", evt => this.onScroll(evt));

    if (this.bulletscontainer)
    {
      //this.bulletscontainer.addEventListener("click", evt => this.onBulletClick(evt));
      this.bulletscontainer.addEventListener("mousedown", evt => this.onBulletClick(evt));
      this.bulletscontainer.addEventListener("touchstart", evt => this.onBulletClick(evt), { passive: true });
    }

    if (this.options.previousbutton)
    {
      this.options.previousbutton.addEventListener("click", evt => this.previousPage(evt));
      this.options.previousbutton.addEventListener("keypress", evt => this.maybePreviousPage(evt)); // in case the button isn't made using an <button> element we need to handle keyboard events ourselves
    }

    if (this.options.nextbutton)
    {
      this.options.nextbutton.addEventListener("click", evt => this.nextPage(evt));
      this.options.nextbutton.addEventListener("keypress", evt => this.maybeNextPage(evt)); // in case the button isn't made using an <button> element we need to handle keyboard events ourselves
    }

    // this.viewport.scrollTo(0,0); // work around Firefox restoring the scrollposition
    this.refresh();
    this.gotoPage(0);
    this.updateNavigationBar();

    if (this.options.debug)
    {
      console.info( "[CarrouselContent]"
                  , { widgetnode: this.widgetnode
                    , viewport:   this.viewport
                    , items:      this.items
                    , bulletscontainer: this.bulletscontainer
                    , pages:      this.pages
                    }
                  );
    }
  }

  maybePreviousPage(evt)
  {
    if (evt.keyCode == 13 || evt.keyCode == 32)
    {
      this.previousPage();
      evt.preventDefault(); // we emulate a <button>, so "space" must trigger our click action, DON'T let the browser think the user wants to scroll the page
    }
  }

  maybeNextPage(evt)
  {
    if (evt.keyCode == 13 || evt.keyCode == 32)
    {
      this.nextPage();
      evt.preventDefault();
    }
  }

  previousPage()
  {
    if (this.options.debug)
      console.log("previousPage");

    if (this.activepage > 0)
      this.gotoPage(this.activepage-1, true);
  }

  nextPage()
  {
    if (this.options.debug)
    {
      console.log( "nextPage"
                 , { activepage: this.activepage
                   , pagescount: this.pages.length
                   , nextactivepage: this.activepage+1
                   });
    }

    if (this.activepage < this.pages.length-1)
      this.gotoPage(this.activepage+1, true);
  }

  gotoPage(idx, animate)
  {
    if (this.options.debug)
      console.log("[CarrouselContent] gotoPage", idx, this.pages[idx], animate);

    if (this.viewport.scrollTo) // asumming SmoothScroll polyfill has loaded (FIXME: check for scrollBehaviour or polyfill existance)
      this.viewport.scrollTo({ left: this.pages[idx], behavior: "smooth" });
    else
      this.viewport.scrollLeft = this.pages[idx]; // SmoothScroll polyfill not loaded yet, IE might not have a scrollTo() function
  }



  onBulletClick(evt)
  {
    if (evt.type == "click")
      evt.preventDefault();

    let bullet = evt.target.closest("."+this.options.bulletclass);
    let idx = this.bullets.indexOf(bullet);

    if (this.options.debug)
      console.log("[CarrouselContent] onButtonClick", bullet, idx);

    this.gotoPage(idx, true);
  }

  onScroll(evt)
  {
    let activepage = this.determineActivePage();
    this.activepage = activepage;
    this.updateNavigationBar();

    if (window.bLazy)
      window.bLazy.revalidate();
  }

  updateNavigationBar()
  {
    if (this.options.previousbutton)
    {
      if (this.activepage == 0)
        this.options.previousbutton.setAttribute("disabled","");
      else
        this.options.previousbutton.removeAttribute("disabled");
    }


    if (this.options.nextbutton)
    {
      if (this.activepage == this.pages.length-1)
        this.options.nextbutton.setAttribute("disabled","");
      else
        this.options.nextbutton.removeAttribute("disabled");
    }

    for (let idx = 0; idx < this.pages.length; idx++)
      this.bullets[idx].classList[idx == this.activepage ? "add" : "remove"](this.options.bulletselectedclass);
  }

  determineActivePage()
  {
    let scrollLeft = this.viewport.scrollLeft;
    let scrollWidth = this.viewport.scrollWidth;

    let containerbounds = this.viewport_inner.getBoundingClientRect();

    if (this.options.debug_determineactivepage)
      console.group("determineActivePage");

    let activepage = -1;

    if (this.options.debug_determineactivepage)
    {
      console.log("Pages", this.pages);
      console.info("ScrollLeft", scrollLeft);
    }


    if (scrollLeft + this.viewport.offsetWidth == scrollWidth)
    {
      if (this.options.debug)
        console.log("Reached last scrollLeft position, so last page.")

      // we reached the end, so it'll always be the last bullet we need to highlight
      activepage = this.pages.length - 1;
      return activepage;
    }


    // Find the page we are in
    for (let idx = 0; idx < this.pages.length-1; idx++)
    {
      let pagex = this.pages[idx];

      if (this.options.debug_determineactivepage)
      {
        console.info("page", idx, "at", pagex, "till", pagex + containerbounds.width);
        console.log("check1", idx == this.pages.length);
        console.log(this.pages[idx+1], scrollLeft);
      }

      if (idx == this.pages.length
          || this.pages[idx+1] > scrollLeft) //pagex + containerbounds.width > scrollLeft)// + 15 + 1) // FIXME: magic padding number
      {
        // Found active page
        activepage = idx;
        break;
      }
    }

    // If we somehow passed without finding the page, it must be the last page
    if (activepage == -1)
      activepage = this.pages.length - 1;

    if (this.options.debug_determineactivepage)
      console.groupEnd();

    return activepage;
  }


  refresh()
  {
    this.recalculatePages();
    this.createJumpButtons();
  }

  recalculatePages()
  {
    if (!this.viewport)
      return;

    if (this.options.debug)
    {
      console.group("[CarrouselContent] recalculatePages", this.options.__test);
      console.log("Items", this.items);
    }

    this.pages = [];
    this.pageselem = [];
    let viewportbounds_inner = this.viewport_inner.getBoundingClientRect();

    let viewportbounds = this.viewport.getBoundingClientRect();
    let scrollLeft = this.viewport.scrollLeft;

    let nextpage_xpos = 0; //viewportbounds.width;


let scrollSnapSupport = true;

    let pagenr = 0;
    for(let item of this.items)
    {
      let itembounds = item.getBoundingClientRect();

      if (itembounds.width == 0) // skip hidden items
        continue;


      let itemx, itemxend;
      if (scrollSnapSupport)
      {
        itemx = Math.floor(itembounds.left - viewportbounds.left) + scrollLeft;
        itemxend = Math.floor(itembounds.right - viewportbounds.left) + scrollLeft;

        // Get amount to keep from the edge... This is the amount we have to scroll less to get there
        let comp = window.getComputedStyle(item); //this.pageselem[idx]);
        let left;
        if (comp.scrollMarginLeft)
        {
          left = parseInt(comp.scrollMarginLeft);

          if (this.options.debug)
            console.log("scrollMarginLeft", left);
        }
        else if (comp.scrollSnapMarginLeft)
        {
          left = parseInt(comp.scrollSnapMarginLeft);

          if (this.options.debug)
            console.log("scrollSnapMarginLeft", left);
        }
        else
        {
          // FIXMNE: or use item[0].getBoundingClientRect().left - viewport.getBoundingClientRect().left als scrollMarginLeft ??
          left = viewportbounds_inner.left - viewportbounds.left; // Don't stoll the first item skipping the whitespace (padding in viewport, margin-left of viewport_iunner)

          if (this.options.debug)
            console.log("Keeping whitespace at start", left);
        }

        // Reduce with the amount we have to scroll less
        // (because the is meant to be whitespace at the left)
        itemx -= left;
        itemxend -= left;
      }
      else
      {
        // use inner because it's useually within the scroll snap bounds
        itemx = Math.floor(itembounds.left) - Math.floor(viewportbounds_inner.left);
        itemxend = Math.floor(itembounds.right) - Math.floor(viewportbounds_inner.left);

        // If the container of the items
        if (this.viewport == this.viewport_inner)
        {
          let scrollLeft = this.viewport.scrollLeft;
          itemx += scrollLeft;
          itemxend += scrollLeft;
        }
      }


      if (this.options.debug)
      {
        console.log({ node: item
                  //  , innerviewport_left: viewportbounds_inner.left
                    , item_left: itembounds.left
                    , item_width: itembounds.width

                    , xstart: itemx
                    , xend:   itemxend
                    });
      }
      //if (itemx >= nextpage_xpos)
      if (itemxend > nextpage_xpos)
      {
        // console.log("Page will start at", nextpage_xpos);
        if (this.options.debug)
          console.log("Page #"+pagenr+" starts at", nextpage_xpos, "and end before", nextpage_xpos + this.viewport.clientWidth);

        this.pages.push(Math.floor(itemx));
        this.pageselem.push(item);
        nextpage_xpos = itemx + this.viewport.clientWidth; // Math.floor(viewportbounds.width);

        //if (this.options.debug)
        //  console.log("Next page might be at", nextpage_xpos);
      }
    }

    if (this.options.debug)
      console.log("[CarrouselContent] pages:", this.pages);

    console.groupEnd();
  }

  createJumpButtons()
  {
    if (!this.viewport || !this.bulletscontainer)
      return;

    let activepage = this.determineActivePage();

    this.bulletscontainer.innerHTML = "";
    this.bullets = [];

    for (let idx = 0; idx < this.pages.length; idx++)
    {
      let bullet = document.createElement("div");
      bullet.className = this.options.bulletclass;
      bullet.classList[idx == activepage ? "add" : "remove"](this.options.bulletselectedclass);
      this.bulletscontainer.appendChild(bullet);
      this.bullets.push(bullet);
    }

    let multiplepages = this.pages.length > 1;
    this.bulletscontainer.style.display = multiplepages ? "" : "none";

    this.widgetnode.classList[multiplepages ? "add" : "remove"]("carrouselcontent--multiplepages");
  }

  onResize()
  {
    this.refresh();
  }
}


//module.exports = { CarrouselContent: CarrouselContent };
export { CarrouselContent };




function resizeAllBlockWidgets()
{
  for(let widget of window.__widget_blocks)
    widget.widget.onResize();
}

dompack.onDomReady(resizeAllBlockWidgets);

window.addEventListener("resize", resizeAllBlockWidgets);
