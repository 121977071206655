import "./praktijkvoorbeelden.scss";

import * as CarrouselContent from "../../../shared/carrouselcontent";
import * as dompack from "dompack";


dompack.register(".widget-praktijkvoorbeelden", initEventsWidget);


function initEventsWidget(widgetnode)
{
  let eventscarrousel = new CarrouselContent.CarrouselContent(widgetnode
            , { viewport:            ".widget-pv__itemswrapper"
              , viewport_inner:      ".widget-pv__items"
              , itemsselector:       ".widget-pv__items > *" // measure the child because .widget-event__block may be display: contents;
              , bulletscontainer:    ".widget-pv__bullets"
              , bulletclass:         "carrouselbullet"
              , bulletselectedclass: "carrouselbullet--selected"

              //, previousbutton:      widgetnode.querySelector(".fullscreenslideshow__previousbutton")
              //, nextbutton:          widgetnode.querySelector(".fullscreenslideshow__nextbutton")

              , debug:  false
              , __test: "pvwidget"
              });
}
