import * as dompack from "dompack";
import * as whintegration from "@mod-system/js/wh/integration";


import { openLinksInNewWindow } from '@mod-publisher/js/linkhandler';
import '@mod-system/js/wh/errorreporting'; //log JS errors to notice log
import Blazy from "../shared/blazy"; // Lazy loading of images
import "../shared/wh.social.sharepage.js";
import * as consenthandler from '@mod-publisher/js/analytics/consenthandler';

import "@mod-juistezorg/webdesigns/shared/web/fontawesome5/css-variables.css";
import "./settings/forms.scss";
import "./settings/site-menubar.scss";
import "./settings/site-sidebar.scss";
import "./settings/widget-rovid.scss";

// Polyfills
import smoothscroll from 'smoothscroll-polyfill'; // For Safari, IE and Edge (works natively in Chrome, Firefox and iOS)

/*
import "../shared/components/skiplinks";
import "../shared/components/spc-dialog";
import "../shared/components/spc-navigationpath";
*/
import "../shared/components/spc-sidebar";
/*
import "../shared/components/spc-tagsblock";
import "../shared/components/spc-pagination/pagination.css";
*/

import "./shared/rtd/rtd.es";

import "./components/autosuggest";
import "./components/bullets.scss";
import "./components/pagegrid/grid-basics.scss";
import "./components/pagegrid/grid-contentarea.scss";
import "./components/skiplinks.scss";
import "./components/toggleextendedcontent"; // also handler .whcomponent-showmore

import "./components/push-footer-to-bottom.scss";

import "./shared/forms";


// Very site specific
import "./juistezorg.scss";
import "./header";
//import "./sidebar";
import "./footer";


// Widgets
import "./widgets/accordion";
import "./widgets/button";
import "./widgets/downloadsandlinks";
import "./widgets/cta";
import "./widgets/image";
import "./widgets/news";
import "./widgets/newsletter";
import "./widgets/events";
import "./widgets/pagehighlights";
import "./widgets/praktijkvoorbeelden";
import "./widgets/photoalbum";
import "./widgets/richtext";
import "./widgets/richtext-with-image";
// import "./widgets/video";
import "./widgets/video-publisher";
import "./widgets/rovid";

import "../shared/widgets/podcast";



// Pages
import "./pages/contentsummary";
import "./pages/news";
import "./pages/newsletter"

import "./pagetemplates/genericoverview";
import "./pages/praktijkvoorbeelden";
import "./pages/transformatieplannen";

import "./pages/regiobeelden"
import "./pages/search";
import "./pages/sitemap";
import "./pages/thema";


import "./site.lang.json";



window._paq = []; // needed for Piwik analytics


smoothscroll.polyfill();


// open external links in new window
var ourdomain = (new URL(location.href)).host.toLowerCase();
let internalhosts =
    [ ourdomain
    , "www.dejuistezorgopdejuisteplek.nl"
    , "dejuistezorgopdejuisteplek.nl"
    ];
openLinksInNewWindow({ internalhosts: internalhosts });


window.addEventListener("load", onAfterLoadingCompleted);


dompack.onDomReady(function()
  {
    //console.info("Site init");

    // lazy loading of images
    window.bLazy = new Blazy({
          offset: 100 // Loads images 100px before they're visible
      });

    // consenthandler.onConsent("video", function() { document.documentElement.classList.add(""); });
    consenthandler.setup("juistezorg-consent", showCookieBanner);
    window.consenthandler = consenthandler;

    /*
    if (document.documentElement.classList.contains("page--formwebtool"))
    {
      // Set class on the <form> so we can style with low specificy,
      // so we can try to prevent overriding the layout of widgets embedded in RTE fields.
      let webtoolform = document.querySelector("form.wh-form");
      webtoolform.classList.add("wh-form--webtoolform");
    }
    */

    initSkipLinks();

    if (whintegration.config.dtapstage == "production") // only the live site should send tracking info
      startTracking();

    window.videoinit();
  });



function initSkipLinks()
{
  document.querySelector(".skiplinks").addEventListener("click", doSkipLinks);
}

function doSkipLinks(evt)
{
  evt.preventDefault();

  // NOTE: Safari won't take scroll-margin-top into account yet for .scrollIntoView
  let main = document.querySelector("main");
  main.focus(); // NOTE: Chrome and Safari will scroll the element into view when we focus
  main.scrollIntoView({ behavior: "smooth" }); // Because of Firefox we need to force scrolling (and also to get smooth scrolling)
}



function onAfterLoadingCompleted()
{
  // When we know the page has loaded we can activate transitions.
  // Some transitions would otherwise trigger a transition to the value which we meant to be the initial one.
  document.documentElement.classList.add("page--loaded");
}

function showCookieBanner()
{
  // We don't show a banner for now, instead we block Video's with a consent layer which when clicked grants consent
  //document.getElementById("cookies__ack").addEventListener("click", () => consenthandler.setConsent(["analytics","remarketing"]));
  //document.getElementById("cookies__deny").addEventListener("click", () => consenthandler.setConsent([]));
}



function startTracking()
{
  (function(window, document, dataLayerName, id) {

    window[dataLayerName]=window[dataLayerName]||[],window[dataLayerName].push({start:(new Date).getTime(),event:"stg.start"});var scripts=document.getElementsByTagName('script')[0],tags=document.createElement('script');

    function stgCreateCookie(a,b,c){var d="";if(c){var e=new Date;e.setTime(e.getTime()+24*c*60*60*1e3),d="; expires="+e.toUTCString()}document.cookie=a+"="+b+d+"; path=/"}

    var isStgDebug=(window.location.href.match("stg_debug")||document.cookie.match("stg_debug"))&&!window.location.href.match("stg_disable_debug");stgCreateCookie("stg_debug",isStgDebug?1:"",isStgDebug?14:-1);

    var qP=[];dataLayerName!=="dataLayer"&&qP.push("data_layer_name="+dataLayerName),isStgDebug&&qP.push("stg_debug");var qPString=qP.length>0?("?"+qP.join("&")):"";

    tags.async=!0,tags.src="//statistiek.rijksoverheid.nl/containers/"+id+".js"+qPString,scripts.parentNode.insertBefore(tags,scripts);

    !function(a,n,i){a[n]=a[n]||{};for(var c=0;c<i.length;c++)!function(i){a[n][i]=a[n][i]||{},a[n][i].api=a[n][i].api||function(){var a=[].slice.call(arguments,0);"string"==typeof a[0]&&window[dataLayerName].push({event:n+"."+i+":"+a[0],parameters:[].slice.call(arguments,1)})}}(i[c])}(window,"ppms",["tm","cm"]);

  })(window, document, 'dataLayer', '006bfb03-b70d-4edc-8295-827181d78d4e');
}
