import "./site-top.scss";
import "./site-menubar.scss";

// import "@mod-juistezorg/webdesigns/shared/components/spc-menubar";
import "@mod-juistezorg/webdesigns/shared/components/spc-sidebar";

import "./header-page.scss";

import "./header-page-default.scss";
import "./header-page-textual.scss";
import "./header-page-bighoneycomb.scss";
import "./header-page-smallhoneycomb.scss";
import "./header-page-fullwidthimage.scss";

import "./header-page-filterbar.scss";

// import "./comp-menubar.es";
import "./comp-menubar.scss";
import "./comp-search.scss";

import "./header-site.es";


function onScroll(evt)
{
  if (!document.scrollingElement) // probably Internet Explorer or and old iOS (pre 9)
    return;

  // document.documentElement.scrollTop // Chrome
  // document.body.scrollTop // Safari
  let scrolly = document.scrollingElement.scrollTop; // iOS 9+, Edge 14+ and FF/Chrome

  // Is there room for the logo at the top left?
  if (scrolly > 15)
    document.documentElement.classList.add("hidedesktopheaderlogo");
  else
    document.documentElement.classList.remove("hidedesktopheaderlogo");


  // document.documentElement.scrollTop // Chrome
  // document.body.scrollTop // Safari

  // Is there room for the logo at the top left?
  if (document.body.clientWidth <= 500 && scrolly > 0)
    document.documentElement.classList.add("hidetitleinfilterheader");
  else
    document.documentElement.classList.remove("hidetitleinfilterheader");


  if (scrolly > 400)
    document.documentElement.classList.add("compactsearchbar");
  else
    document.documentElement.classList.remove("compactsearchbar");

  if (scrolly > 270)
    document.documentElement.classList.add("compactsearchbar-filtersactive");
  else
    document.documentElement.classList.remove("compactsearchbar-filtersactive");
}


window.addEventListener("scroll", evt => onScroll(evt));
