import "./genericoverview-map.scss";
import * as dompack from 'dompack';
import * as whintegration from '@mod-system/js/wh/integration';
import getTid from "@mod-tollium/js/gettid";
import 'leaflet/dist/leaflet.css';

import Leaflet from 'leaflet'
import * as MarkerClusterGroup from 'leaflet.markercluster';



export default class praktijkvoorbeeldenMap
{
  constructor(node)
  {
  //  console.log("[praktijkvoorbeeldenMap] constructor", node);

    this.node = node;

    this.markers = [];
    this.mapitems = [];
    this.language = whintegration.config.locale.split("-")[0];

    // let coord = locations[0].gps.split(",");//set initial center on first marker

    this.map = Leaflet.map( node
                          , { zoomControl: false

                            // Zet vast rond Nederland
                            , maxBounds: [ [50.70,3.00]
                                         , [54.00,7.23]
                                         ]

                            , maxZoom: 10 //18
                            , minZoom: 7
                            , scrollWheelZoom: false
                            });
    // this.map.setView([ 1*coord[0], 1*coord[1]], 16);
    //map.scrollWheelZoom.disable();

    window.map = this.map; // for debugging

    /*
    80 x 90 (at 2x zoom)

    32 x 37 (1x, without shadow)
    40 x 45 (1x, with shadow)
    */
    this.icon = Leaflet.icon({
        iconUrl:      whintegration.config.imgroot + 'marker.2x.png',
        iconSize:     [40, 45], // size of the icon
        iconAnchor:   [16, 37], // point of the icon which will correspond to marker's location
        popupAnchor:  [0, -43] // point from which the popup should open relative to the iconAnchor
    });


    // Use OpenStreetMap it's own tile hosting
    // (this can be overloaded sometimes causing some tiles to load slow or occasionally even fail to load)
    Leaflet.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                       , { attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                         , maxZoom: 18
                         , minZoom: 7
                         }).addTo(this.map);

/*
    // Basic map via MapTiler.com
    Leaflet.tileLayer( 'https://api.maptiler.com/maps/basic/{z}/{x}/{y}.png?key=A1xiVGUEIYM6JpZPqPdK'
                     , { tileSize: 512
                       , zoomOffset: -1
                       , minZoom:  7
                       , maxZoom: 18
                       , attribution: '<a href="https://www.maptiler.com/copyright/" target="_blank">© MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">© OpenStreetMap contributors</a>'
                       , crossOrigin: true
                      }).addTo(map);
*/

/*
    // Street map via MapTiler.com
    Leaflet.tileLayer( 'https://api.maptiler.com/maps/streets/{z}/{x}/{y}.png?key=A1xiVGUEIYM6JpZPqPdK'
                     , { tileSize:    512
                       , zoomOffset:   -1
                       , minZoom:       1
                       , maxZoom:      18
                       , attribution:  '<a href="https://www.maptiler.com/copyright/" target="_blank">© MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">© OpenStreetMap contributors</a>'
                       , crossOrigin:  true
                     }).addTo(map);
*/

    /*
    // "52.211157,5.969923"
    let marker = Leaflet.marker([ 52.211157, 5.969923 ],{ icon: this.icon, title: "Title" });
    marker.addTo(this.map);
    */

    document.querySelector(".map__zoomin").addEventListener("click",  evt => { evt.preventDefault(); this.map.zoomIn();  });
    document.querySelector(".map__zoomout").addEventListener("click", evt => { evt.preventDefault(); this.map.zoomOut(); });
  }


  centerOnTheNetherlands()
  {
    this.map.fitBounds([ [ 50.803721015,  3.31497114423 ]
                       , [ 53.5104033474, 7.09205325687 ]
                       ]);
  }



  removeAllMarkers()
  {
    /*
    for(let marker of this.markers)
    {
      this.map.removeLayer(marker);
    }
    */
    if (this.clustergroup)
    {
      this.map.removeLayer(this.clustergroup);
      this.clustergroup = null;
    }
  }


  addMarkers(locations)
  {
    this.clustergroup = new Leaflet.markerClusterGroup(
              { maxClusterRadius:    60
              , showCoverageOnHover: false
              , iconCreateFunction: function (cluster)
                    {
                      let itemcount = cluster.getChildCount();
                      var markers = cluster.getAllChildMarkers();

                      let markerurl = `${whintegration.config.imgroot}marker.svg`;

                      let icon = <div class="jzojpmapitem"><img src={markerurl} /><div>{itemcount}</div></div>;

                      return L.divIcon({ html: icon, className: 'mycluster', iconSize: L.point(40, 40) });
                    }
              });

    for(let loc of locations)
    {
      let marker = this.getMarker(loc);
      // marker.addTo(this.map);
      this.clustergroup.addLayer(marker);
    }
    this.map.addLayer(this.clustergroup);

/*
Center around markers
    if( this.markers.length > 1 )
    {
      let group = new Leaflet.featureGroup(this.markers);
      this.map.fitBounds(group.getBounds());
    }
*/
    this.centerOnTheNetherlands();
  }


  getMarker(data)
  {
    let coord = data.gps.split(",");
    if( coord.length != 2 )
      return;

    let marker = Leaflet.marker([ 1*coord[0], 1*coord[1] ],{ icon: this.icon, title: data.title });

    let info = <div class="location">
                 <div class="location__location">{data.regions}</div>
                 <a class="location__name" href={data.link}>{data.title}</a>
                 {data.image ? <img class="location__image" src={data.image} /> : null}
               </div>;
    marker.bindPopup(info);

    this.markers.push(marker);

    this.mapitems.push({ id:     data.id
                       , marker: marker
                       });

    return marker;
  }
}
