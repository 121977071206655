import "./search.scss";
import * as dompack from "dompack";


dompack.onDomReady(initSearch);


function initSearch()
{
  let search = document.querySelector(".search__header");
  if (!search)
    return; // Whe're not on the search page

  let hdrsearch = document.querySelector(".header-top__search");
  hdrsearch.addEventListener("submit", evt => onHeaderSearch(evt));

  if (search)
    search.addEventListener("change", evt => onChange(evt));
}


function onHeaderSearch(evt)
{
  evt.preventDefault();
  let hdrsearch = document.querySelector(".header-top__search");
  let form = document.querySelector(".search__header");
  form.query.value = hdrsearch.query.value;
  form.submit();
}


function onChange(evt)
{
  let form = document.querySelector(".search__header");
  form.submit();
}
