import "./newsletter.scss";
import * as dompack from "dompack";


dompack.onDomReady(tryToFixForm);


function tryToFixForm()
{
  // console.info("tryToFixForm");

  let formcontainer = document.querySelector(".mailplusform");
  if (!formcontainer)
    return;

  let firstelem = formcontainer.firstElementChild;
  if (firstelem.id.substring(0,6) == "mpform")
  {
    setTimeout(tryToFixForm, 100);
    return; // content hasn't been replaced yet
  }

  fixForm();
}


function fixForm()
{
  // console.info("readdy to fix");
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const email = urlParams.get("email");


  // Progressively enhance the MailPlus form to conform to the wh-styledinput method (empty label after input[type="checkbox"])
  let options = document.querySelectorAll(".mpMultipleLabel");
  for(let option of options)
  {
    if (option.__replaced)
      continue;

    let styledcheckbox = document.createElement("label");
    styledcheckbox.setAttribute("for", option.getAttribute("for"));
    option.parentNode.insertBefore(styledcheckbox, option);

    option.__replaced = true;
  }


  let formcontainer = document.querySelector(".mailplusform");

  // Spotler doesn't provide us with a name of the field.
  // Also the field id seems to change sometimes.
  // So whe'll lookup the email field by first looking up the associated label.
  let nodes = document.querySelectorAll("label.descriptionLabel");
  let emailfield;
  for (let node of nodes)
  {
    if (node.textContent == "E-mailadres")
    {
      let input_id = node.getAttribute("for");
      emailfield = document.getElementById(input_id);
    }
  }
  if (emailfield) // we might not have been able to find it
  {
    emailfield.value = email;
    emailfield.type = "email"; // MailPlus uses "text" for this field
  }
  else
    console.error("e-mail field not found in MailPlus form");
}
