import "./regiobeelden.scss";
import * as dompack from "dompack";
import * as whintegration from "@mod-system/js/wh/integration";


dompack.onDomReady(init);

let current_indeling = "zorgkantoor";

let scrollDuration = 500;
let map;

function init()
{
  let maps = document.querySelectorAll(".zorgregiokaart");
  if (maps.length == 0)
    return;

  window.addEventListener("click", checkRegioSelection); // The first time we take over the scroll using the hashchange, block a next time to prevent the browser scrolling itself (without regards of the fixed/sticky header)
  window.addEventListener("click", checkRegioShowMoreButton);

  for (let map of maps)
    map.addEventListener("click", doCheckForRegioSelection);

  let indeling_pulldown = document.getElementById("filterpanel__type");
  if (indeling_pulldown)
    indeling_pulldown.addEventListener("change", onSelectedRegioIndeling);

  document.querySelector(".regiobeelden__list__returnbutton").addEventListener("click", resetCurrentMap);
  document.querySelector(".regiobeelden__map__caption__returnbutton").addEventListener("click", resetCurrentMap);

  window.addEventListener("hashchange", onHashChange);

  // light up all regions for which we have information
  for (let regio of whintegration.config.obj.regions)
  {
    let mapnode = document.getElementById(regio.vectorid);
    if (!mapnode)
      console.error("Missing vectorid", regio.vectorid);
   else
     mapnode.classList.add("regio--active");
  }

  doRefreshShownRegioIndelingList();
  onHashChange();
}


function resetCurrentMap()
{
  document.querySelector(".regiobeelden__overview").classList.remove("regiobeelden__overview--activeselection");

  resetMapSelection(map);

  // clear hash from URL
  history.replaceState(null, null, window.location.pathname);
}


function resetMapSelection(mapnode)
{
  map.classList.remove("zorgregiokaart--activeselection");
  document.querySelector(".regiobeelden__overview").classList.remove("regiobeelden__overview--activeselection");

  let infopanels = document.querySelectorAll(".regio");
  for (let panel of infopanels)
    panel.classList.remove("regio--selected");
}





function onSelectedRegioIndeling(evt)
{
  setRegioIndeling(document.getElementById("filterpanel__type").value);
}

function setRegioIndeling(type)
{
  if (type == current_indeling)
    return;

  // Switch pulldown
  document.getElementById("filterpanel__type").value = type;

  if (map)
    resetCurrentMap(map);

  doRefreshShownRegioIndelingList();
}

function doRefreshShownRegioIndelingList()
{
  let indeling_pulldown = document.getElementById("filterpanel__type");
  let type = "";
  if (!indeling_pulldown)
  {
    let list = document.querySelector(`.regiobeelden__list__list[data-regioindeling]`);
    let indeling = list.getAttribute("data-regioindeling");
    type = indeling;
  }
  else
    type = indeling_pulldown.value;

  let container = document.querySelector(".regiobeelden__overview");

  map = document.querySelector(`.zorgregiokaart[data-indeling="${type}"`);

  current_indeling = type;

  // console.log(`Switching to list for ${type}.`);

  container.setAttribute("data-regioindeling", type);

  let regiocategories = document.querySelectorAll(".regiobeelden__list__list");

  for (let categorycontainer of regiocategories)
  {
    let tag = categorycontainer.getAttribute("data-regioindeling");
    categorycontainer.toggleAttribute("hidden", tag != type);
  }
}



function getHash()
{
  let hash = location.hash;
  if (location.hash[0] == "#")
    hash = hash.substring(1);

  return hash;
}


function onHashChange(evt)
{
  let hash = getHash();
  if (hash == "") // FIXME: or deselect all?
    return;

  setRegionByHash(hash);
}

function setRegionByHash(hash)
{
  let region = getRegionByTag(hash);
  if (!region)
  {
    console.error("region with hash", hash, "not found.");
    return;
  }

  selectRegionByVectorID(region.vectorid);
}

// region selected on the map?
function doCheckForRegioSelection(evt)
{
  let kaart = evt.target.closest(".zorgregiokaart");
  let indeling = kaart.getAttribute("data-indeling");

  let regionode = evt.target.closest(".zorgregiokaart__regio");

  /*
  console.info({ kaart: kaart
               , indeling: indeling
               , regionnode: regionode
               });
  */

  let selectedregion = selectRegionByVectorID(regionode.id);
  if (selectedregion)
    history.replaceState(null, null/*WHBase.config.obj.title*/, "#"+selectedregion.tag);
}

function getRegionByTag(tag)
{
  for (let regio of whintegration.config.obj.regions)
  {
    if (regio.tag == tag)
      return regio;
  }
  return null;
}

function selectRegionByVectorID(vectorid)
{
  // console.log("selectRegionByVectorID", vectorid);

  /*
  region.tag - the value on the URL
  region.vectorid - the id of the corresponding element in the map (SVG)
  */

  // console.info("Platte lijst van regio's", whintegration.config.obj.regions);


  // Lookup the vectorid (so we can use it to lookup on which map it is)
  let selectedregion = null;
  for (let regio of whintegration.config.obj.regions)
  {
    if (regio.vectorid == vectorid)
    {
      selectedregion = regio;
      break;
    }
  }


  if (!selectedregion)
  {
    console.error(`Regio ID ${vectorid} is niet ingevuld bij de vectorid van één van de regio's in de backend.`);
    return;
  }


  // console.log("Region entry", selectedregion);

  setRegioIndeling(selectedregion.indeling);

  // console.log("Map", map);

  map.classList.add("zorgregiokaart--activeselection");
  document.querySelector(".regiobeelden__overview").classList.add("regiobeelden__overview--activeselection");



  // console.info("SVG MAP", map, "regios", svg_regios);

  // Update the selection of all regios in the SVG
  let svg_regios = map.querySelectorAll(".zorgregiokaart__regio");
  for (let region_shape of svg_regios)
  {
    region_shape.classList.toggle("regio--selected", region_shape.id == vectorid);
  }


  // - Update the selected status of all regio info panels
  // - Scroll to the selected panel
  let infopanels = document.querySelectorAll(".regio");
  for (let panel of infopanels)
  {
    if (panel.id == selectedregion.tag)
    {
      panel.classList.add("regio--selected");

      let scroll = -panel.getBoundingClientRect().top;
      let pos = window.pageYOffset - scroll;// + this.options.offsety;

      window.scrollTo(
          { top:       pos - 100
          , behavior: "smooth"
          });
    }
    else
      panel.classList.remove("regio--selected");
  }



  return selectedregion;
}






function checkRegioSelection(evt)
{
  let anchor = dompack.closest(evt.target, "a");
  if (!anchor)
    return;

  let href = anchor.getAttribute("href");
  if (href.substr(0,1) != "#")
    return;

  evt.preventDefault();

  if (href != window.location.hash)
  {
    let hash = href.substring(1);
    setRegionByHash(hash);
    history.replaceState(null, null/*WHBase.config.obj.title*/, "#"+hash);
  }
  else
    console.log("already there");
}

function checkRegioShowMoreButton(evt)
{
  let toggle = dompack.closest(evt.target, ".regio__readmore");
  if (!toggle)
    return;

  let expandregion = dompack.closest(evt.target, ".regio");

  //console.log("Toggle", expandregion);

  if (!expandregion)
    return;

  toggle.setAttribute("aria-expanded", "true");

  let regions = document.querySelectorAll(".regiobeelden__list .regio");
  for (let region of regions)
  {
    if (region != expandregion)
      region.classList.remove("regio--expanded");
  }

  expandregion.classList.toggle("regio--expanded");

  //let panel = document.getElementById(toggle.getAttribute("aria-controls"));
  //panel.focus();
}
