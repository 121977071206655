// import "./header-site-base.scss";
// import "./header-site.scss";

import initMenuBar from "@mod-juistezorg/webdesigns/shared/components/spc-menubar";

dompack.register(".spc-menubar", initMenuBar);


import * as dompack from "dompack";


// FIXME: The might be a flash of the menu disappearing when the font is being loaded.
//        This should only happen when testing or loading the site from a clean cache.


// Upon scroll set a class to indicate we have scrolled
// (we can use this for example to shrink the header, add a dropshadow or change it's background color)
//window.addEventListener("scroll", evt => onScroll(evt));


dompack.register(".header-menubar__menubar", initMenubarOverflowChecking);


// Fallback for iOS <13.4 and other browsers lacking support for resizeObserver
if (!window.ResizeObserver)
{
  window.addEventListener("load", hideMenubarIfOverflowing);
  window.addEventListener("resize", hideMenubarIfOverflowing);
}


dompack.onDomReady(initSearchBar);




let searchtoggle;


function initSearchBar()
{
  let searchpanel = document.querySelector(".header-searchpanel-content");
  if (!searchpanel)
    return;

  searchpanel.addEventListener("keyup", evt => doCheckKeypress(evt));

  searchtoggle = document.querySelector(".header-menubar__showsearchpanel");
  if (searchtoggle)
    searchtoggle.addEventListener("click", evt => doActivateSearchPanel(evt));

  let closebutton = document.querySelector(".header-searchpanel-closebutton");
  closebutton.addEventListener("click", evt => doCloseSearchPanel(evt));
}

function doActivateSearchPanel(evt)
{
  if (!document.documentElement.classList.contains("page--search"))
    setSearchPanelExpanded(true);

  document.getElementById("spc-searchbar__input").focus();
}

function setSearchPanelExpanded(expand)
{
  if (expand)
  {
    document.documentElement.classList.add("searchpanel-active");
    searchtoggle.setAttribute("aria-expanded", "true");
  }
  else
  {
    document.documentElement.classList.remove("searchpanel-active");
    searchtoggle.setAttribute("aria-expanded", "false");
  }
}

function doCloseSearchPanel(evt)
{
  setSearchPanelExpanded(false);
  // FIXME: restore focus?
}

function doCheckKeypress(evt)
{
  if (evt.key == "Escape")
  {
    evt.preventDefault();
    evt.stopPropagation();
    setSearchPanelExpanded(false);
  }
}



function initMenubarOverflowChecking()
{
  console.info("initMenubarOverflowChecking");

  if (!window.ResizeObserver)
    return; // unsupported API

  let menubar_container = document.querySelector(".header-menubar__menubar");
  if (!menubar_container) // Some kind of special page or widget preview page?? (which doesn't have the menubar)
    return;

  let menubar = menubar_container.querySelector(".spc-menubar-branch--horizontal");

  /* Check for changes in
     - the amount of space the menu uses
     - the amount of space available to the menu

     We need to do this in case of live changes to letter-spacing.
     Accessibility auditors do this to check if text keeps working without
     overflow of overlapping. (real users might reload the page in this case)
  */
  let observer = new ResizeObserver(onRelayoutMenuBar);
  observer.observe(menubar_container); // observe the available width for the menu
  observer.observe(menubar); // The menubar (items) which can be wider than it's container (we need to observe this because we cannot observe the scrollWidth of the container)
}

function onRelayoutMenuBar(evt)
{
  // console.info("Checking if menubar content fits");
  hideMenubarIfOverflowing();
}

function hideMenubarIfOverflowing()
{
  /*
  .header-menubar - The panel used on every page on the site which includes the logo, home-link, menu bar, sidebar/mobile menu toggle, searchbutton
    .header-menubar__menubar - Container for all menu items
      ul.spc-menubar-branch--horizontal - The horizontal menustrip
  */

  let site_menubarpanel = document.querySelector(".header-menubar");
  let menuitems = document.querySelector(".header-menubar__menubar");
  // let aftermenuitems = menuitems.nextElementSibling;

  // First attempt: try whether the normal desktop menu fits
  site_menubarpanel.classList.remove("header-menubar--hidemenuitems");
  site_menubarpanel.classList.remove("header-menubar--sitetitle-multiline");

  let menubar_container = document.querySelector(".header-menubar__content");
  let menu_overflow = menuitems.scrollWidth > menuitems.clientWidth;


  /*
  console.log({ mode:            "normal"
              , clientWidth:     menuitems.clientWidth
              , scrollWidth:     menuitems.scrollWidth
              , overflow:        menu_overflow
              });
  */

  if (menu_overflow)
  {
    // 2nd attempt: Try a more compact version of the desktop header
    site_menubarpanel.classList.add("header-menubar--sitetitle-multiline");
    menu_overflow = menuitems.scrollWidth > menuitems.clientWidth;

    /*
    console.log({ mode:            "compact"
                , clientWidth:     menuitems.clientWidth
                , scrollWidth:     menuitems.scrollWidth
                , overflow:        menu_overflow
                , mb_classes: menubar.classList
                });
    */

    if (!menu_overflow)
    {
      // Making the sitetitle wrap has freed up enough room to make the menubar visible
      site_menubarpanel.classList.remove("header-menubar--hidemenuitems");
      site_menubarpanel.classList.add("header-menubar--sitetitle-multiline");
    }
    else
    {
      site_menubarpanel.classList.add("header-menubar--hidemenuitems");
      site_menubarpanel.classList.remove("header-menubar--sitetitle-multiline");

      // Since menuitems have now been hidden, we must also check if the sitetitle
      // causes an overflow:
      menu_overflow = menubar_container.scrollWidth > menubar_container.clientWidth;

      /*
      console.log({ mode:            "hide mainmenu"
                  , clientWidth:     menuitems.clientWidth
                  , scrollWidth:     menuitems.scrollWidth
                  , overflow:        menu_overflow
                  , mb_classes: menubar.classList
                  });
      */

      // If we still overflow, the site title must be too long (probably in mobile mode)
      if (menu_overflow) // we need both the menu to be hidden AND the site title to be compact (multiline)
      {
        site_menubarpanel.classList.add("header-menubar--sitetitle-multiline");

        /*
        console.log({ mode:            "hide mainmenu AND compact"
                    , clientWidth:     menuitems.clientWidth
                    , scrollWidth:     menuitems.scrollWidth
                    , overflow:        menu_overflow
                    , mb_classes: menubar.classList
                    });
        */
      }
    }
  }
}

/*
function onScroll(evt)
{
  let scrolly = document.scrollingElement.scrollTop;

  if (scrolly > 80)
    document.documentElement.classList.add("pageheader--fixed");
  else
    document.documentElement.classList.remove("pageheader--fixed");
}
*/
