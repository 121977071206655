import "./genericoverview.scss";
import "./genericoverview-viewmode.scss";
import "./genericoverview-filters.scss";



import JSONRPC from "@mod-system/js/net/jsonrpc";
import FilteredOverview from "../../../shared/components/filteredoverview";
import * as dompack from "dompack";
import getTid from "@mod-tollium/js/gettid";

import praktijkvoorbeeldenMap from "./genericoverview-map.es";


let overviewmap = null;
let current_viewmode = "";


dompack.register(".praktijkvoorbeelden__map .widget-map__container__map", node => initMap(node));
dompack.register(".pvoverview-filters-form", node => initPraktijkVoorbeeldenFilters(node));



//See: https://developers.piwik.pro/en/latest/data_collection/web/guides.html#custom-events
function pushPraktijkvoorbeeldenPiwikEvent(action, name, value)
{
  if( !window._paq )
    window._paq = [];
  window._paq.push(["trackEvent", "praktijkvoorbeelden" /* category */, action, name, value]);
}

function getFloatingTeaserText(totalfound, totalshown)
{
  if( totalfound == 1 )
    return getTid('juistezorg:webdesigns.juistezorg.js.filteredoverview.tease-result', totalfound);
  else if( totalfound > 1 )
    return getTid('juistezorg:webdesigns.juistezorg.js.filteredoverview.tease-results', totalfound);
  else
    return getTid("juistezorg:webdesigns.juistezorg.js.filteredoverview.tease-noresults");
}


function initPraktijkVoorbeeldenFilters(formnode)
{
  let settings =
          { node_results:             document.querySelector(".praktijkvoorbeelden__results .resultscontainer")
          , additionalform:           document.querySelector(".filteredoverview__feedbackandviewbar__content")
          , statestorageid:           "praktijkvoorbeelden"

          , method:                   "rpc"
          , rpc:                      new JSONRPC({ url: "/wh_services/juistezorg/rpc/" })
          , rpcsearchfunction:        formnode.dataset.rpcsearchfunction // "SearchArticles"

          , defaultfilters:           { weergave: "lijst" }
          , fields_hidetag:           ["weergave"]
          , fields_notforfiltering:   ["weergave"]
          , fields_dontcountasactive: ["weergave", "sorteer"] // fields that don't influence the amount of results
          , showsummary:              false //  also adds the "- filtered on" text

         // Callbacks
          , getfloatingteasertext:    getFloatingTeaserText
          , onfilterchange:           onFilterChange
          , ongotrpcresults:          updateMapByResults

          , debug: false
          };

  if (formnode.hasAttribute("data-hiddenfilters"))
    settings.hiddenfilters = JSON.parse(formnode.dataset.hiddenfilters); // addition filters to send to the RPC (for example whfsid of the folder)

  // Send the filters that were used when landing on this page.
  // (we only get here if there are filters specified on the URL)
  // Since the initial landing has the results generated into the page serverside we also send stats here.
  // let filters = getFiltersValue();
  //sendSearchEvent('', null /*initial search*/, resultstotal);
  window.fover = new FilteredOverview(formnode, settings);
  window.fover.refreshResults();
}

function updateMapByResults(results)
{
  //console.log(results.mapdata);
  if (!overviewmap)
    return;

  overviewmap.removeAllMarkers();
  overviewmap.addMarkers(results.mapdata.items);
}

function onFilterChange(filters)
{
  onViewModeChange(filters.weergave);
}

function onViewModeChange(viewmode)
{
  // console.info("onViewModeChange", current_viewmode, viewmode);
  if (current_viewmode == viewmode) // nothing to do if we are already in the correct viewmode
    return;

  if (current_viewmode != "") // don't trigger for the initial setting of viewmode
    pushPraktijkvoorbeeldenPiwikEvent("viewchange","viewmode",viewmode);

  current_viewmode = viewmode;

  document.documentElement.classList.toggle("viewmode--map",  viewmode == "kaart");
  document.documentElement.classList.toggle("viewmode--list", viewmode != "kaart");

  if (viewmode == "kaart")
    overviewmap.map.invalidateSize(); // inform leafletjs to refresh(), which it already does upon a resize event

  let sortoption = document.querySelector(".filteredoverview__sort");
  if (sortoption)
    sortoption.toggleAttribute("disabled", viewmode == "kaart");
}




function initMap(node)
{
  overviewmap = new praktijkvoorbeeldenMap(node);
}
